:root {
  --primary-color: #2720ff;
  --download-button-color: #d6a3ef;
  --background-color: #f0f0f0;
  --text-color: #333;
}

body {
  color: var(--text-color);
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Inter, Arial, sans-serif;
  display: flex;
  overflow: hidden;
}

#bgVideo {
  z-index: -1;
  object-fit: cover;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}

.container {
  z-index: 1;
  background-color: #fffc;
  border-radius: 15px;
  width: 90%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0 8px 32px #0000001a;
}

h1 {
  text-align: center;
  color: var(--text-color);
  font-size: 1.7rem;
  font-weight: 600;
}

h2 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.input-container {
  margin-bottom: 1rem;
  position: relative;
}

#urlInput {
  color: var(--text-color);
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  padding: .8rem;
  font-size: 1rem;
}

#submitBtn {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: .8rem;
  font-size: 1rem;
  transition: background-color .3s;
}

#submitBtn:hover {
  opacity: .9;
}

.spinner-container {
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  display: flex;
}

.spinner {
  border: 3px solid #0000001a;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: 1s ease-in-out infinite spin;
  display: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.result-container {
  margin-top: 2rem;
  display: none;
}

.thumbnail-container {
  text-align: center;
  margin-bottom: 1rem;
}

#thumbnail {
  border-radius: 5px;
  max-width: 100%;
}

.download-buttons {
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.download-link {
  background-color: var(--download-button-color);
  color: #fff;
  border-radius: 5px;
  padding: .6rem 1.2rem;
  font-size: .9rem;
  font-weight: 300;
  text-decoration: none;
  transition: opacity .3s;
  display: inline-block;
}

.download-link:hover {
  opacity: .9;
}
/*# sourceMappingURL=index.16580de9.css.map */
