/* frontend/src/styles.css */

:root {
  --primary-color: #2720ff;
  --download-button-color: #d6a3ef;
  --background-color: #f0f0f0;
  --text-color: rgb(51, 51, 51);
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', Arial, sans-serif;
  color: var(--text-color);
  overflow: hidden;
}
#bgVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  object-fit: cover;
}

.container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  z-index: 1;
}


h1 {
  text-align: center;
  color: var(--text-color);
  font-size: 1.7rem;
  font-weight: 600;
}

h2 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.input-container {
  position: relative;
  margin-bottom: 1rem;
}

#urlInput {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: var(--text-color);
    font-size: 1rem;
    box-sizing: border-box;
    text-align: center; /* This centers the text */
}


#submitBtn {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#submitBtn:hover {
  opacity: 0.9;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.spinner {
  display: none;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.result-container {
  display: none;
  margin-top: 2rem;
}

.thumbnail-container {
  text-align: center;
  margin-bottom: 1rem;
}

#thumbnail {
  max-width: 100%;
  border-radius: 5px;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.download-link {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  background-color: var(--download-button-color);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  font-weight: 300;
  font-size: 0.9rem;
}

.download-link:hover {
  opacity: 0.9;
}
